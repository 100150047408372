<template>
	<div>
    <DataTable
        :getQuery="queries.get"
        :createQuery="queries.create"
        :removeQuery="queries.remove"
        :updateQuery="queries.update"
        :headers="headers"
        :where="where"
        :validate="validate"
        :connector="connector"
        :createFields="createFields"
        icon="mdi"
        result_name="categories"
        title="categories"
        subtitle="List of products type categories"
    ></DataTable>
  </div>
</template>
<script>
import DataTable from '@/components/datatable'
export default {
	components: {DataTable},
	data: () => ({
		connector: {},
		where: {},
    createFields: [],
    productTypes: [],
		queries: require('@/gql/productCategories.gql'),
    validate: [],
		headers: [
			{ text: 'Category Name', align: 'left', value: 'name' },
			{ text: 'Date Added', align: 'left', value: 'createdAt' },
			{ text: 'Actions', value: 'action', sortable: false },
		]
	  }),

  methods: {
    initConditions(){
      let id = this.$route.params.id
      if(id){
        this.connector = {
          product_type: {
            connect: { id }
          }
        }

        this.where = {
          product_type: { id }
        }
      }
    }
  },

  created(){
      this.initConditions()
      this.$apollo.query({
        query: require('@/gql/productCategories.gql').get,
      }).then(res=> {
        console.log('HACK*****',res)
        this.productTypes = res.data.productTypes.map(type => ({
          text: type.name,
          value: type.id,
          slug: type.slug,
          subs: type.categories.map(category => ({text: category.name, value: category.id}))
        }))
        console.log('HACKyyy*****',this.productTypes)
        this.createFields = [
          { name:'name', label: 'Category Name', icon: 'mdi-plus'},
          { name:'product_type', label: 'Product Type', type: 'select', icon: 'mdi-tag'},
        ]
      })
	}

}
</script>
