import {gql} from 'apollo-boost'

const fragments = `
{
    id
    name
    product_type{
        id
        name
        slug
    }
    createdAt
}
`

export const create = gql`
    mutation createCategory($data: CategoryCreateInput!){
        createCategory(data: $data)${fragments}
    }
`

export const get = gql`
    query categories($where: CategoryWhereInput){
        categories(where: $where)${fragments}
    }
`

export const update = gql`
    mutation updateCategory(
        $data: CategoryUpdateInput!, 
        $where: CategoryWhereUniqueInput!
    ){
        updateCategory(data: $data, where: $where)${fragments}
    }
`

export const remove = gql`
    mutation deleteCategory($where: CategoryWhereUniqueInput!){
        deleteCategory(where: $where)${fragments}
    }
`